import { SiteContext } from "@organic-return/foundation-gatsby-plugin-drupal/src/components/common/SiteContext"
import { IListingSummary } from "@organic-return/foundation-react/src/lib/Listing"
import { Sort } from "@organic-return/foundation-react/src/components/Search/Sort"
import React from "react"
import {
  Check,
  Choose,
  Text,
  DateRange,
} from "@organic-return/foundation-react/src/components/Search/Filter"
import { IAgent } from "@organic-return/foundation-react/src/lib/Contact"
import { EListingVitalsLayout } from "@organic-return/foundation-react/src/components/Listing/ListingVitals"
import { EListingDetailsLayout } from "@organic-return/foundation-react/src/components/Listing/ListingDetails"
import { graphql, navigate, useStaticQuery } from "gatsby"
import { ESellFormLayout } from "@organic-return/foundation-react/src/components/Forms/SellForm"
import { ISearchContext } from "@organic-return/foundation-react/src/components/Search"
import { FooterLayoutFormColumn } from "@organic-return/foundation-react/src/components/Footer/FooterLayoutFormColumn"
import { EContactFormLayout } from "@organic-return/foundation-react/src/components/Forms/ContactForm"
import { EWsrvTransform } from "@organic-return/foundation-react/src/lib/Media/util"
import { EContactCardLayout } from "@organic-return/foundation-react/src/components/Contact"
import { getRecaptchaToken, ISiteMenuItem } from "@organic-return/foundation-gatsby-plugin-drupal/src/lib/utils"
import { EActivationBreakpoint, FilterDropdown, IFilterDropdown } from "@organic-return/foundation-react/src/components/Search/FilterDropdown"
import { CommunityLayoutHeroBox } from "@organic-return/foundation-react/src/components/Community/CommunityLayoutHeroBox"
import axios from "axios"

export const SiteContextProvider: React.FC = ({ children }: any) => {
  const data = useStaticQuery(graphql`
    query SiteContextProviderQuery {
      config: drupalSiteConfiguration {
        rentalSearchPath
        listingsSearchPath
      }
      allListing {
        distinct(field: {subType: SELECT})
      }
    }
  `)
  const selectStyles = {
    control: (base: object) => ({
      ...base,
      borderColor: "transparent",
      background: "transparent",
      borderBottomColor: "var(--color-composite-text)",
      borderRadius: 0,
    }),
    option: (base: object) => ({
      ...base,
      color: "var(--color-body-text)",
    }),
    singleValue: (base: any) => ({
      ...base,
      color: "var(--color-composite-text)",
      textTransform: "uppercase",
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      paddingRight: "0px",
      color: "var(--color-composite-text)",
    }),
    indicatorSeparator: (base: object) => ({
      ...base,
      display: "none",
    }),
    placeholder: (base: object) => ({
      ...base,
      color: "var(--color-composite-text)",
      whiteSpace: "nowrap",
      textTransform: "uppercase",
    }),
    valueContainer: (base: object) => ({
      ...base,
      paddingLeft: 0,
      textTransform: "uppercase",
    }),
  }

  const selectStylesCompact = {
    control: (base: object) => ({
      ...base,
      background: "transparent",
      borderColor: "#AEB3B7",
      paddingLeft: "1rem",
      paddingRight: "1rem",
      borderRadius: 4,
    }),
    option: (base: object) => ({
      ...base,
      color: "var(--color-body-text)",
    }),
    singleValue: (base: any) => ({
      ...base,
      color: "var(--color-body-text)",
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      paddingRight: "0px",
      color: "var(--color-body-text)",
    }),
    indicatorSeparator: (base: object) => ({
      ...base,
      display: "none",
    }),
    placeholder: (base: object) => ({
      ...base,
      color: "#808080",
      whiteSpace: "nowrap",
      fontSize: "1rem",
    }),
    valueContainer: (base: object) => ({
      ...base,
      paddingLeft: 0,
      fontSize: "1rem",
    }),
  }

  const priceFilters = (context: ISearchContext, style?: any) => (
    <>
      <Choose
        context={context}
        label="Min Price"
        placeholder="Min Price"
        customFilter={(arg, value) => {
          let argl = arg as IListingSummary
          let vstr = value as string
          return (argl.price || 0) >= parseInt(vstr) || false
        }}
        param="minPrice"
        resolver={arg => arg.price}
        reactSelectStyles={style ?? selectStyles}
        options={[
          { label: "> $200k", value: "200000" },
          { label: "> $500k", value: "500000" },
          { label: "> $750K", value: "750000" },
          { label: "> $1M", value: "1000000" },
          { label: "> $1.5M", value: "1500000" },
          { label: "> $2M", value: "2000000" },
          { label: "> $3M", value: "3000000" },
          { label: "> $5M", value: "5000000" },
          { label: "> $7.5M", value: "7500000" },
          { label: "> $10M", value: "10000000" },
        ]}
      />
      <Choose
        context={context}
        label="Max Price"
        placeholder="Max Price"
        customFilter={(arg, value) => {
          let argl = arg as IListingSummary
          let vstr = value as string
          return (argl.price || 0) <= parseInt(vstr) || false
        }}
        param="maxPrice"
        resolver={arg => arg.price}
        reactSelectStyles={style ?? selectStyles}
        options={[
          { label: "< $200k", value: "200000" },
          { label: "< $500k", value: "500000" },
          { label: "< $750K", value: "750000" },
          { label: "< $1M", value: "1000000" },
          { label: "< $1.5M", value: "1500000" },
          { label: "< $2M", value: "2000000" },
          { label: "< $3M", value: "3000000" },
          { label: "< $5M", value: "5000000" },
          { label: "< $7.5M", value: "7500000" },
          { label: "< $10M", value: "10000000" },
        ]}
      />
    </>
  )
  const rentalPriceFilters = (context: ISearchContext) => (
    <>
      <Choose
        context={context}
        label="Min Price"
        placeholder="Min Price"
        customFilter={(arg, value) => {
          let argl = arg as IListingSummary
          let vstr = value as string
          return (argl.priceLow || 0) >= parseInt(vstr) || false
        }}
        param="minPrice"
        resolver={arg => arg.priceLow}
        reactSelectStyles={selectStyles}
        options={[
          { label: "> $1k", value: "1000" },
          { label: "> $2k", value: "2000" },
          { label: "> $3k", value: "3000" },
          { label: "> $5k", value: "5000" },
          { label: "> $7k", value: "7000" },
          { label: "> $10k", value: "10000" },
          { label: "> $15k", value: "15000" },
          { label: "> $20k", value: "20000" },
          { label: "> $30k", value: "30000" },
          { label: "> $40k", value: "40000" },
        ]}
      />
      <Choose
        context={context}
        label="Max Price"
        placeholder="Max Price"
        customFilter={(arg, value) => {
          let argl = arg as IListingSummary
          let vstr = value as string
          return (argl.priceLow || 0) <= parseInt(vstr) || false
        }}
        param="maxPrice"
        resolver={arg => arg.priceLow}
        reactSelectStyles={selectStyles}
        options={[
          { label: "< $1k", value: "1000" },
          { label: "< $2k", value: "2000" },
          { label: "< $3k", value: "3000" },
          { label: "< $5k", value: "5000" },
          { label: "< $7k", value: "7000" },
          { label: "< $10k", value: "10000" },
          { label: "< $15k", value: "15000" },
          { label: "< $20k", value: "20000" },
          { label: "< $30k", value: "30000" },
          { label: "< $40k", value: "40000" },
        ]}
      />
    </>
  )
  const bedroomsFilter = function (context: ISearchContext, style?: any) {
    return (
      <>
        <Choose
          context={context}
          label="Beds"
          placeholder="Beds"
          customFilter={(arg, value) => {
            let argl = arg as IListingSummary
            let vstr = value as string
            return (argl.beds || 0) >= parseInt(vstr) || false
          }}
          param="beds"
          resolver={arg => arg.beds}
          reactSelectStyles={style ?? selectStyles}
          options={[
            { label: "0+", value: "0" },
            { label: "1+", value: "1" },
            { label: "2+", value: "2" },
            { label: "3+", value: "3" },
            { label: "4+", value: "4" },
            { label: "5+", value: "5" },
            { label: "6+", value: "6" },
            { label: "7+", value: "7" },
            { label: "8+", value: "8" },
          ]}
        />
      </>
    )
  }
  const bathroomsFilter = function (context: ISearchContext, style?: any) {
    return (
      <>
        <Choose
          context={context}
          label="Baths"
          placeholder="Baths"
          customFilter={(arg, value) => {
            let argl = arg as IListingSummary
            let vstr = value as string
            return (argl.baths || 0) >= parseInt(vstr) || false
          }}
          param="baths"
          resolver={arg => arg.baths}
          reactSelectStyles={style ?? selectStyles}
          options={[
            { label: "0+", value: "0" },
            { label: "1+", value: "1" },
            { label: "2+", value: "2" },
            { label: "3+", value: "3" },
            { label: "4+", value: "4" },
            { label: "5+", value: "5" },
            { label: "6+", value: "6" },
            { label: "7+", value: "7" },
            { label: "8+", value: "8" },
          ]}
        />
      </>
    )
  }

  const subTypes = data.allListing?.distinct &&
    data.allListing.distinct.map((subType: any) => {
      return {
        value: subType,
        label: subType
      }
    })

  /**
   * Standard filters for global Real Estate listing search.
   */
  const listingStandardFilters = (context: ISearchContext) => (
    <>
      <Choose
        context={context}
        placeholder="Location"
        label="Location"
        param="location"
        resolver={arg => arg.address.city}
        match="any"
        reactSelectStyles={selectStyles}
      />
      {bedroomsFilter(context)}
      {bathroomsFilter(context)}
      <Choose
        context={context}
        placeholder="Type"
        label="Type"
        param="type"
        resolver={arg => arg.subType}
        sort="alpha"
        match="any"
        reactSelectStyles={selectStyles}
      />
      {priceFilters(context)}
    </>
  )
  /**
   * Standard filters for global Real Estate listing search.
   */
  const listingEntryFilters = (context: ISearchContext) => (
    <>
      <Choose
        context={context}
        placeholder="Location"
        label="Location"
        param="location"
        resolver={arg => arg.address.city}
        match="one"
        reactSelectStyles={selectStyles}
      />
      <Choose
        context={context}
        placeholder="Type"
        label="Type"
        param="type"
        resolver={arg => arg.subType}
        sort="alpha"
        match="one"
        reactSelectStyles={selectStyles}
        options={subTypes}
      />
      {priceFilters(context)}
    </>
  )
  /**
   * Standard filters for community page Real Estate listing search.
   */
  const communityStandardFilters = (context: ISearchContext) => (
    <>
      <Choose
        context={context}
        placeholder="Type"
        label="Type"
        param="type"
        resolver={arg => arg.subType}
        sort="count"
        reactSelectStyles={selectStyles}
        match="any"
      />
      {priceFilters}
      <Choose
        context={context}
        placeholder="Features"
        label="Features"
        param="features"
        resolver={arg => arg.features}
        sort="count"
        match="all"
        reactSelectStyles={selectStyles}
      />
    </>
  )
  /**
   * Sorts to be used in all Listing (real estate) searches
   */
  const listingSort = (context: ISearchContext) => (
    <Sort
      context={context}
      className="text-center py-1"
      label="Sort by: "
      sorts={[
        {
          label: "Price: $$$ to $",
          key: "priceDESC",
          cb: (arg0, arg1) => {
            let l0 = arg0 as IListingSummary
            let l1 = arg1 as IListingSummary
            return (l1.price || 0) - (l0.price || 0)
          },
        },
        {
          label: "Price: $ to $$$",
          key: "priceASC",
          cb: (arg0, arg1) => {
            let l0 = arg0 as IListingSummary
            let l1 = arg1 as IListingSummary
            return (l0.price || 0) - (l1.price || 0)
          },
        },
        {
          label: "Bedrooms: High to Low",
          key: "bedsDESC",
          cb: (arg0, arg1) => {
            let l0 = arg0 as IListingSummary
            let l1 = arg1 as IListingSummary
            return (l1.beds || 0) - (l0.beds || 0)
          },
        },
        {
          label: "Bedrooms: Low to High",
          key: "bedsASC",
          cb: (arg0, arg1) => {
            let l0 = arg0 as IListingSummary
            let l1 = arg1 as IListingSummary
            return (l0.beds || 0) - (l1.beds || 0)
          },
        },
        {
          label: "Sq Ft: High to Low",
          key: "sqftDESC",
          cb: (arg0, arg1) => {
            let l0 = arg0 as IListingSummary
            let l1 = arg1 as IListingSummary
            return (l1.sqft || 0) - (l0.sqft || 0)
          },
        },
        {
          label: "Sq Ft: Low to High",
          key: "sqftASC",
          cb: (arg0, arg1) => {
            let l0 = arg0 as IListingSummary
            let l1 = arg1 as IListingSummary
            return (l0.sqft || 0) - (l1.sqft || 0)
          },
        },
        {
          label: "Lot Size: High to Low",
          key: "lotDESC",
          cb: (arg0, arg1) => {
            let l0 = arg0 as IListingSummary
            let l1 = arg1 as IListingSummary
            return (l1.lotacres || 0) - (l0.lotacres || 0)
          },
        },
        {
          label: "Lot Size: Low to High",
          key: "lotASC",
          cb: (arg0, arg1) => {
            let l0 = arg0 as IListingSummary
            let l1 = arg1 as IListingSummary
            return (l0.lotacres || 0) - (l1.lotacres || 0)
          },
        },
        {
          label: "Last Updated",
          key: "statusTSDESC",
          cb: (arg0, arg1) => {
            let l0 = arg0 as IListingSummary
            let l1 = arg1 as IListingSummary
            return (l1.statusTS || "").localeCompare(l0.statusTS || "")
          },
        },
      ]}
    />
  )
  /**
   * Middle filter for real estate listing search
   */
  const listingMiddleFilters = (context: ISearchContext) => (
    <>
      <Text
        context={context}
        placeholder="MLS#, City, Zip, Area"
        param="text"
        label="MLS#, City, Zip, Area"
        inputClassName="bg-transparent text-composite-text border-composite-text placeholder:text-inherit"
        resolver={(arg: any, text) => {
          let argl = arg as IListingSummary
          let match = text.toLowerCase()
          return (argl.mlsID && argl.mlsID.toLowerCase().includes(match)) ||
            (argl.address.text &&
              argl.address.text.toLowerCase().includes(match)) ||
            (argl.majorArea && argl.majorArea.toLowerCase().includes(match)) ||
            (argl.minorArea && argl.minorArea.toLowerCase().includes(match)) ||
            (argl.subdivision &&
              argl.subdivision.toLowerCase().includes(match)) ||
            (argl.neighborhood &&
              argl.neighborhood.toLowerCase().includes(match))
            ? true
            : false
        }}
      />
      <Check
        context={context}
        label="Our Properties Only"
        inputClassName=" bg-transparent"
        param="ours"
        className="text-composite-text"
        resolver={(arg: any) => {
          let argl = arg as IListingSummary
          return argl.exclusive || false
        }}
      />
    </>
  )
  /**
   * Standard filters for global Rental entry listing search.
   */
  const rentalEntryFilters = (context: ISearchContext) => (
    <>
      <Choose
        context={context}
        placeholder="Location"
        label="Location"
        param="address.city"
        resolver={arg => arg.minorArea}
        match="one"
        reactSelectStyles={selectStyles}
        options={[
          {
            value: "Aquinnah",
            label: "Aquinnah",
          },
          {
            value: "Chilmark",
            label: "Chilmark",
          },
          {
            value: "Edgartown",
            label: "Edgartown",
          },
          {
            value: "Oak Bluffs",
            label: "Oak Bluffs",
          },
          {
            value: "Vineyard Haven",
            label: "Vineyard Haven",
          },
          {
            value: "West Tisbury",
            label: "West Tisbury",
          },
        ]}
      />
      <DateRange
        context={context}
        label="Dates"
        param="dates"
        className=" py-[.4rem] border-b"
        dateInputClassNames="rounded-none bg-transparent uppercase placeholder:text-inherit"
      />
    </>
  )
  /**
   * Advanced filters for global Rental entry listing search.
   */
  const rentalEntryAdvancedFilters = (context: ISearchContext) => (
    <>
      <Choose
        context={context}
        placeholder="Location"
        label="Location"
        param="address.city"
        resolver={arg => arg.minorArea}
        match="one"
        reactSelectStyles={selectStyles}
        options={[
          {
            value: "Aquinnah",
            label: "Aquinnah",
          },
          {
            value: "Chilmark",
            label: "Chilmark",
          },
          {
            value: "Edgartown",
            label: "Edgartown",
          },
          {
            value: "Oak Bluffs",
            label: "Oak Bluffs",
          },
          {
            value: "Vineyard Haven",
            label: "Vineyard Haven",
          },
          {
            value: "West Tisbury",
            label: "West Tisbury",
          },
        ]}
        className="col-span-2"
      />
      {rentalPriceFilters}
      <DateRange
        context={context}
        label="Dates"
        param="dates"
        className=" py-[.4rem] border-b"
        dateInputClassNames="rounded-none bg-transparent uppercase placeholder:text-inherit"
      />
      <div className="col-span-2 row-span-2 grid grid-col-2 gap-3">
        <label className="col-span-2 text-uppercase">ADDITIONAL FILTER</label>
        <Check
          context={context}
          label="Air Conditioning"
          inputClassName=" bg-transparent"
          param="air"
          className="text-composite-text self-end"
          resolver={(arg: any) => {
            let argl = arg as IListingSummary
            return argl.features?.find((str) => str == "A/C") !== undefined
          }}
        />
        <Check
          context={context}
          label="Waterfront"
          inputClassName=" bg-transparent"
          param="waterfront"
          className="text-composite-text self-end"
          resolver={(arg: any) => {
            let argl = arg as IListingSummary
            return argl.features?.find((str) => str == "Waterfront") !== undefined
          }}
        />
        <Check
          context={context}
          label="Linens provided"
          inputClassName=" bg-transparent"
          param="linens"
          className="text-composite-text self-end"
          resolver={(arg: any) => {
            let argl = arg as IListingSummary
            return argl.features?.find((str) => str == "Linens Provided") !== undefined
          }}
        />
        <Check
          context={context}
          label="Pool"
          inputClassName=" bg-transparent"
          param="pool"
          className="text-composite-text self-end"
          resolver={(arg: any) => {
            let argl = arg as IListingSummary
            return argl.features?.find((str) => str == "Pool") !== undefined
          }}
        />
      </div>
    </>
  )
  /**
   * Standard filters for global Rental listing search.
   */
  const rentalStandardFilters = (context: ISearchContext) => (
    <>
      <Choose
        context={context}
        placeholder="Location"
        label="Location"
        param="address.city"
        resolver={arg => arg.address.city}
        match="any"
        reactSelectStyles={selectStyles}
      />
      <Choose
        context={context}
        placeholder="Features"
        label="Features"
        param="features"
        resolver={arg => arg.features}
        sort="count"
        match="all"
        reactSelectStyles={selectStyles}
      />
      <DateRange
        context={context}
        label="Dates"
        param="dates"
        className=" py-[.4rem] border-b"
        dateInputClassNames="rounded-none bg-transparent uppercase placeholder:text-inherit"
      />
      {rentalPriceFilters}
      <Text
        context={context}
        placeholder="Keywords, City, Address"
        param="text"
        label="Keywords, City, Address"
        inputClassName="w-full bg-transparent text-composite-text border-composite-text placeholder:text-inherit"
        resolver={(arg: any, text) => {
          let argl = arg as IListingSummary
          let match = text.toLowerCase()
          return (argl.address.text &&
            argl.address.text.toLowerCase().includes(match)) ||
            (argl.subdivision &&
              argl.subdivision.toLowerCase().includes(match)) ||
            (argl.neighborhood &&
              argl.neighborhood.toLowerCase().includes(match))
            ? true
            : false
        }}
      />
    </>
  )
  /**
   * Middle filter for real estate listing search
   */
  const rentalMiddleFilters = (context: ISearchContext) => (
    <>
      <Text
        context={context}
        placeholder="Keywords, City, Address"
        param="text"
        label="Keywords, City, Address"
        inputClassName="bg-transparent text-composite-text border-composite-text placeholder:text-inherit"
        resolver={(arg: any, text) => {
          let argl = arg as IListingSummary
          let match = text.toLowerCase()
          return (argl.address.text &&
            argl.address.text.toLowerCase().includes(match)) ||
            (argl.subdivision &&
              argl.subdivision.toLowerCase().includes(match)) ||
            (argl.neighborhood &&
              argl.neighborhood.toLowerCase().includes(match))
            ? true
            : false
        }}
      />
    </>
  )
  /**
   * Sorts to be used in all Rental searches
   */
  const rentalSort = (context: ISearchContext) => (
    <Sort
      context={context}
      className="text-center py-1"
      label="Sort by: "
      sorts={[
        {
          label: "Price: $$$ to $",
          key: "priceDESC",
          cb: (arg0, arg1) => {
            let l0 = arg0 as IListingSummary
            let l1 = arg1 as IListingSummary
            return (
              (l1.price || l1.priceHigh || 0) - (l0.price || l0.priceHigh || 0)
            )
          },
        },
        {
          label: "Price: $ to $$$",
          key: "priceASC",
          cb: (arg0, arg1) => {
            let l0 = arg0 as IListingSummary
            let l1 = arg1 as IListingSummary
            return (
              (l0.price || l0.priceLow || 0) - (l1.price || l1.priceLow || 0)
            )
          },
        },
        {
          label: "Bedrooms: High to Low",
          key: "bedsDESC",
          cb: (arg0, arg1) => {
            let l0 = arg0 as IListingSummary
            let l1 = arg1 as IListingSummary
            return (l1.beds || 0) - (l0.beds || 0)
          },
        },
        {
          label: "Bedrooms: Low to High",
          key: "bedsASC",
          cb: (arg0, arg1) => {
            let l0 = arg0 as IListingSummary
            let l1 = arg1 as IListingSummary
            return (l0.beds || 0) - (l1.beds || 0)
          },
        },
        {
          label: "Sleeps: High to Low",
          key: "sleepsDESC",
          cb: (arg0, arg1) => {
            let l0 = arg0 as IListingSummary
            let l1 = arg1 as IListingSummary
            return (l1.sleeps || 0) - (l0.sleeps || 0)
          },
        },
        {
          label: "Sleeps: Low to High",
          key: "sleepsASC",
          cb: (arg0, arg1) => {
            let l0 = arg0 as IListingSummary
            let l1 = arg1 as IListingSummary
            return (l0.sleeps || 0) - (l1.sleeps || 0)
          },
        },
      ]}
    />
  )
  const peoplePrimaryFilters = (context: ISearchContext) => (
    <>
      <Text
        context={context}
        placeholder="Keywords, Name, Email, etc."
        label="Keywords, Name, Email, etc."
        param="text"
        inputClassName="bg-transparent text-composite-text border-composite-text"
        resolver={(arg: any, text) => {
          let argl = arg as IAgent
          let match = text.toLowerCase()
          return (argl.mlsID && argl.mlsID.toLowerCase().includes(match)) ||
            (argl.fullName && argl.fullName.toLowerCase().includes(match)) ||
            (argl.first && argl.first.toLowerCase().includes(match)) ||
            (argl.last && argl.last.toLowerCase().includes(match)) ||
            (argl.email && argl.email.toLowerCase().includes(match)) ||
            (argl.workTitle && argl.workTitle.toLowerCase().includes(match))
            ? true
            : false
        }}
      />
    </>
  )

  /**
 * Communities tabs global filters
 */
  const communitiesTabsGlobalFilters = function (context: ISearchContext) {
    return (
      <>
        <Choose
          context={context}
          placeholder="Type"
          label="Type"
          param="type"
          resolver={arg => arg.subType}
          sort="alpha"
          match="one"
          reactSelectStyles={selectStyles}
          options={subTypes}
        />
        {priceFilters(context)}
        <Text
          context={context}
          placeholder="MLS#, City, Zip, Area"
          param="text"
          label="MLS#, City, Zip, Area"
          inputClassName="w-full bg-transparent text-composite-text border-composite-text placeholder:text-inherit"
          resolver={(arg: any, text) => {
            let argl = arg as IListingSummary
            let match = text.toLowerCase()
            return (argl.mlsID && argl.mlsID.toLowerCase().includes(match)) ||
              (argl.address.text &&
                argl.address.text.toLowerCase().includes(match)) ||
              (argl.majorArea && argl.majorArea.toLowerCase().includes(match)) ||
              (argl.minorArea && argl.minorArea.toLowerCase().includes(match)) ||
              (argl.subdivision &&
                argl.subdivision.toLowerCase().includes(match)) ||
              (argl.neighborhood &&
                argl.neighborhood.toLowerCase().includes(match))
              ? true
              : false
          }}
        />
      </>
    )
  }

  const LeftArrow = (props: any) => {
    return (
      <button
        current-slide={props.currentSlide}
        slide-count={props.slideCount}
        onClick={props.onClick}
        className={`prev-arrow absolute z-10 cursor-pointer left-0 drop-shadow bg-transparent`}
      >
        <span className="block h-full w-full"></span>
      </button>
    );
  }

  const RightArrow = (props: any) => {
    return (
      <button
        current-slide={props.currentSlide}
        slide-count={props.slideCount}
        onClick={props.onClick}
        className={`next-arrow absolute z-10 cursor-pointer right-0 drop-shadow bg-transparent`}
      >
        <span className="block h-full w-full"></span>
      </button>
    );
  }

  const compactSearchFilters = function (context: ISearchContext) {
    /**
   *
   */
    const baseDropdownProps: Partial<IFilterDropdown> = {
      fieldsBoxClassName: "bg-body-bg",
      buttonClassName: "!text-base !text-body-text-inverted after:!border-body-text-inverted",
    }

    return (
      <div className="sm:col-span-2 lg:col-span-4 flex flex-col items-start lg:flex-row lg:justify-between lg:items-center gap-3">
        <div className="border inline-flex items-center border-body-text-inverted rounded">
          <Text
            context={context}
            placeholder="Address or MLS#"
            param="text"
            label="Address or MLS#"
            inputClassName="w-60 !border-0 bg-transparent text-body-text-inverted placeholder:text-body-text-inverted font-['EB_Garamond_Regular'] text-sm"
            className="col-span-3"
            resolver={(arg: any, text) => {
              let argl = arg as IListingSummary
              let match = text.toLowerCase()
              return (argl.mlsID && argl.mlsID.toLowerCase().includes(match)) ||
                (argl.address.text &&
                  argl.address.text.toLowerCase().includes(match)) ||
                (argl.majorArea && argl.majorArea.toLowerCase().includes(match)) ||
                (argl.minorArea && argl.minorArea.toLowerCase().includes(match)) ||
                (argl.subdivision &&
                  argl.subdivision.toLowerCase().includes(match)) ||
                (argl.neighborhood &&
                  argl.neighborhood.toLowerCase().includes(match))
                ? true
                : false
            }}
          />
          <div className="w-12 p-4 border-l border-body-text-inverted [&>svg]:!text-body-text-inverted">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path d="M10.3333 9.33333H9.80667L9.62 9.15333C10.2733 8.39333 10.6667 7.40667 10.6667 6.33333C10.6667 3.94 8.72667 2 6.33333 2C3.94 2 2 3.94 2 6.33333C2 8.72667 3.94 10.6667 6.33333 10.6667C7.40667 10.6667 8.39333 10.2733 9.15333 9.62L9.33333 9.80667V10.3333L12.6667 13.66L13.66 12.6667L10.3333 9.33333ZM6.33333 9.33333C4.67333 9.33333 3.33333 7.99333 3.33333 6.33333C3.33333 4.67333 4.67333 3.33333 6.33333 3.33333C7.99333 3.33333 9.33333 4.67333 9.33333 6.33333C9.33333 7.99333 7.99333 9.33333 6.33333 9.33333Z" fill="#FFFFFF" />
            </svg>
          </div>
        </div>
        <FilterDropdown
          classNames="w-full grow"
          title="Filters"
          fieldsBoxClassName="!w-full !max-w-full !shadow-none lg:!p-0 !relative"
          deactivationBreakpoint={EActivationBreakpoint.lg}
          buttonClassName="!text-base !text-body-text-inverted after:!border-body-text-inverted"
        >
          <div className="w-full flex flex-col lg:flex-row lg:justify-between lg:items-center gap-y-2">
            <div className="flex flex-col lg:flex-row gap-x-7 gap-y-2">
              <FilterDropdown
                title="Location"
                classNames="font-light [&>button]:!uppercase"
                buttonClassName="inverted"
                children={(
                  <div className="text-body-text flex flex-col">
                    <span className="font-bold mb-2">Location</span>
                    <div className="grid grid-cols-1">
                      <Choose
                        context={context}
                        placeholder="Location"
                        label="Location"
                        param="address.city"
                        resolver={arg => arg.address.city}
                        match="any"
                        reactSelectStyles={selectStylesCompact}
                        showAllEntriesOption={true}
                      />
                    </div>
                  </div>
                )}
                {...baseDropdownProps}
              />
              <FilterDropdown
                title="Beds"
                classNames="font-light [&>button]:!uppercase"
                children={(
                  <div className="text-body-text flex flex-col">
                    <span className="font-bold mb-2">Beds</span>
                    <div className="flex flex-row">
                      {bedroomsFilter(context, selectStylesCompact)}
                    </div>
                  </div>
                )}
                {...baseDropdownProps}
              />
              <FilterDropdown
                title="Baths"
                classNames="font-light [&>button]:!uppercase"
                children={(
                  <div className="text-body-text flex flex-col">
                    <span className="font-bold mb-2">Baths</span>
                    <div className="flex flex-row">
                      {bathroomsFilter(context, selectStylesCompact)}
                    </div>
                  </div>
                )}
                {...baseDropdownProps}
              />
              <FilterDropdown
                title="Type"
                classNames="font-light [&>button]:!uppercase"
                children={(
                  <div className="text-body-text flex flex-col">
                    <span className="font-bold mb-2">Type</span>
                    <div className="flex flex-row">
                      <Choose
                        context={context}
                        placeholder="Type"
                        label="Type"
                        param="type"
                        resolver={arg => arg.subType}
                        sort="alpha"
                        match="any"
                        reactSelectStyles={selectStylesCompact}
                      />
                    </div>
                  </div>
                )}
                {...baseDropdownProps}
              />
              <FilterDropdown
                title="Price"
                classNames="font-light [&>button]:!uppercase"
                children={(
                  <div className="text-body-text flex flex-col">
                    <span className="font-bold mb-2">Price</span>
                    <div className="flex justify-start gap-x-4">{priceFilters(context, selectStylesCompact)}</div>
                  </div>
                )}
                {...baseDropdownProps}
              />
              <FilterDropdown
                title="More..."
                classNames="hidden lg:block xl:hidden font-light [&>button]:!uppercase"
                children={(
                  <div className="text-body-text flex flex-col">
                    <div className="flex flex-col gap-3">
                      <Check
                        context={context}
                        label="Our Properties Only"
                        inputClassName="bg-transparent"
                        param="ours"
                        className="text-body-text"
                        resolver={(arg: any) => {
                          let argl = arg as IListingSummary
                          return argl.exclusive || false
                        }}
                      />
                    </div>
                  </div>
                )}
                {...baseDropdownProps}
              />
              <Check
                context={context}
                label="Our Properties Only"
                inputClassName="bg-transparent"
                param="ours"
                className="text-composite-text lg:hidden xl:block font-light !uppercase"
                resolver={(arg: any) => {
                  let argl = arg as IListingSummary
                  return argl.exclusive || false
                }}
              />
            </div>
          </div>
        </FilterDropdown>
        <Choose
          context={context}
          placeholder="Status"
          label="status"
          param="statuses"
          resolver={arg => arg.status}
          match="any"
          className="hidden"
        />
      </div>
    )
  }

  const locationOrigin = typeof window !== "undefined" ? window.location.origin : "";
  const siteMenuItems: ISiteMenuItem[] = [
    {
      isFolder: true,
      menuText: "Home Search",
      children: [
        {
          isFolder: false,
          menuText: "Aspen",
          path: `${locationOrigin}/home-search/Aspen-Real-Estate`,
        },
        {
          isFolder: false,
          menuText: "Snowmass Village",
          path: `${locationOrigin}/home-search/Snowmass-Village-Real-Estate`,
        },
        {
          isFolder: false,
          menuText: "Old Snowmass",
          path: `${locationOrigin}/home-search/Old-Snowmass-Real-Estate`,
        },
        {
          isFolder: false,
          menuText: "Basalt",
          path: `${locationOrigin}/home-search/Basalt-Real-Estate`,
        },
        {
          isFolder: false,
          menuText: "Carbondale",
          path: `${locationOrigin}/home-search/Carbondale-Real-Estate`,
        },
        {
          isFolder: false,
          menuText: "Sold Properties",
          path: `${locationOrigin}/home-search/sold-properties`,
        },
        {
          isFolder: false,
          menuText: "Search All Properties",
          path: `${locationOrigin}/search`,
        },
      ]
    },
    {
      isFolder: false,
      menuText: "Buy",
      path: "/buy"
    },
    {
      isFolder: false,
      menuText: "Sell",
      path: "/sell"
    },
    {
      isFolder: true,
      menuText: "About",
      children: [
        {
          isFolder: false,
          menuText: "About",
          path: "/about"
        },
        {
          isFolder: false,
          menuText: "Resource Center",
          path: "/about/resource-center"
        },
        {
          isFolder: false,
          menuText: "Blog",
          path: "/about/blog"
        }
      ]
    },
    {
      isFolder: true,
      menuText: "Communities",
      children: [
        {
          isFolder: true,
          menuText: "Aspen",
          children: [
            {
              isFolder: false,
              menuText: "Red Mountain",
              path: "/Aspen-Snowmass/Communities-neighborhoods/red-mountain#showMap=true"
            },
            {
              isFolder: false,
              menuText: "East Aspen",
              path: "/Aspen-Snowmass/Communities-neighborhoods/east-aspen#showMap=true"
            },
            {
              isFolder: false,
              menuText: "Central Core",
              path: "/Aspen-Snowmass/Communities-neighborhoods/central-core#showMap=true"
            },
            {
              isFolder: false,
              menuText: "West End",
              path: "/Aspen-Snowmass/Communities-neighborhoods/west-end#showMap=true"
            },
            {
              isFolder: false,
              menuText: "Smuggler",
              path: "/Aspen-Snowmass/Communities-neighborhoods/smuggler#showMap=true"
            },
            {
              isFolder: false,
              menuText: "West Aspen",
              path: "/Aspen-Snowmass/Communities-neighborhoods/west-aspen#showMap=true"
            },
            {
              isFolder: false,
              menuText: "Brush Creek Village",
              path: "/Aspen-Snowmass/Communities-neighborhoods/brush-creek-village#showMap=true"
            },
          ]
        },
        {
          isFolder: true,
          menuText: "Snowmass",
          children: [
            {
              isFolder: false,
              menuText: "Snowmass Village",
              path: "/Aspen-Snowmass/Communities-neighborhoods/snowmass-village#showMap=true"
            },
            {
              isFolder: false,
              menuText: "Woodrun",
              path: "/Aspen-Snowmass/Communities-neighborhoods/woodrun#showMap=true"
            },
            {
              isFolder: false,
              menuText: "Base Village",
              path: "/Aspen-Snowmass/Communities-neighborhoods/base-village#showMap=true"
            },
            {
              isFolder: false,
              menuText: "Upper Village",
              path: "/Aspen-Snowmass/Communities-neighborhoods/upper-village#showMap=true"
            },
            {
              isFolder: false,
              menuText: "Woodrun Complexes",
              path: "/Aspen-Snowmass/Communities-neighborhoods/woodrun-complexes#showMap=true"
            },
            {
              isFolder: false,
              menuText: "Ridge Run",
              path: "/Aspen-Snowmass/Communities-neighborhoods/ridge-run#showMap=true"
            },
            {
              isFolder: false,
              menuText: "Meadow Ranch",
              path: "/Aspen-Snowmass/Communities-neighborhoods/meadow-ranch#showMap=true"
            },
            {
              isFolder: false,
              menuText: "Fox Run",
              path: "/Aspen-Snowmass/Communities-neighborhoods/fox-run#showMap=true"
            },
            {
              isFolder: false,
              menuText: "Lower Village",
              path: "/Aspen-Snowmass/Communities-neighborhoods/lower-village#showMap=true"
            },
            {
              isFolder: false,
              menuText: "Two Creeks",
              path: "/Aspen-Snowmass/Communities-neighborhoods/two-creeks#showMap=true"
            },
            {
              isFolder: false,
              menuText: "Snowmass Club",
              path: "/Aspen-Snowmass/Communities-neighborhoods/snowmass-club#showMap=true"
            },
            {
              isFolder: false,
              menuText: "Divide",
              path: "/Aspen-Snowmass/Communities-neighborhoods/divide#showMap=true"
            },
          ]
        },
        {
          isFolder: true,
          menuText: "Basalt",
          children: [
            {
              isFolder: false,
              menuText: "Old Town",
              path: "/old-town"
            },
            {
              isFolder: false,
              menuText: "Elk Run",
              path: "/elk-run"
            },
            {
              isFolder: false,
              menuText: "Roaring Fork Club",
              path: "/Aspen-Snowmass/Communities-neighborhoods/roaring-fork-club#showMap=true"
            },            
          ]
        },
      ]
    },
  ]

  /**
   * Wrap our pages in the SiteContext to provide configuration and
   * customization of various site elements.  See all usages of useSiteContext()
   * in @organic-return/foundation-gatsby-plugin-agility
   */
  return (
    <SiteContext.Provider
      value={{        
        listingLayoutProps: {
          vitalsProps: {
            className: "mb-3 inverted uppercase",
            layout: EListingVitalsLayout.column1Border1Alt2,
            locationIcon: false,
            twoLineAddress: true,
            omitAddressParts: ["state", "postal"],
          },
          formProps: {
            title: "Request Information",
            classNames: "strictTwoColumn inverted property-contact",
            inputTextAreaLabel: "Add a message here...",
            inputTextClassNames: "font-['EB_Garamond_Regular'] text-sm w-full",
            inputTextAreaClassName: "font-['EB_Garamond_Regular'] w-full",
          },
          similarCardProps: {
            linkText: "View Listing",
            vitalsLayout: EListingVitalsLayout.row1Border0,
          },
          summaryProps: {
            className: "non-exlusive max-w-screen-xl mx-auto p-4 font-['EB_Garamond_Regular'] text-[15px]",
            showFeatures: false,
          },
          mapProps: { title: "", className: "w-full mb-10" },
          detailsProps: {
            title: "DETAILS",
            layout: EListingDetailsLayout.groupNone,
            className:
              "bg-zinc-200 flex flex-col items-center px-10 pt-5 pb-14 mt-10",
          },
          similarCarouselProps: { title: "Similar Properties" },
          rowClassName: "max-w-screen-xl mx-auto",
          showContactUsForm: true,
          contactUsFormProps: {
            layout: EContactFormLayout.column2static,
            inputTextClassNames: "[&::placeholder]:!text-[var(--color-body-text)] font-['EB_Garamond_Regular'] text-sm",
            invertedForm: false,
            inputTextAreaLabel: "Message...",
            inputTextAreaClassName: "font-['EB_Garamond_Regular']",
            children: (<div></div>),
          },
          galleryProps: {
            showCaptions: false
          }
        },
        exclusiveLayoutProps: {
          vitalsProps: {
            composite: true,
            className: "uppercase z-50",
            layout: EListingVitalsLayout.column1Border1,
            locationIcon: false,
          },
          summaryProps: {
            showFeatures: false,
            className: "font-['EB_Garamond_Regular'] text-[15px]",
          },
          formProps: {
            title: "",
            classNames: "strictTwoColumn inverted property-contact",
            inputTextClassNames: "placeholder:!text-body-text-inverted font-['EB_Garamond_Regular'] text-sm",
            inputTextAreaLabel: "Add a message here...",
            inputTextAreaClassName: "font-['EB_Garamond_Regular']",
          },
          similarCardProps: {
            linkText: "View Listing",
            vitalsLayout: EListingVitalsLayout.row1Border0,
          },
          mapProps: { title: "", className: "w-full max-w-screen-xl mx-auto mb-10" },
          detailsProps: {
            title: "DETAILS",
            layout: EListingDetailsLayout.groupNone,
            className: "max-w-screen-xl mx-auto px-10 my-24",
          },
          similarCarouselProps: { title: "Similar Properties" },
          rowClassName: "max-w-screen-xl mx-auto",
          showContactUsForm: true,
          contactUsFormProps: {
            layout: EContactFormLayout.column2static,
            invertedForm: false,
            inputTextClassNames: "[&::placeholder]:!text-[var(--color-body-text)] font-['EB_Garamond_Regular'] text-sm",
            inputTextAreaLabel: "Message...",
            inputTextAreaClassName: "font-['EB_Garamond_Regular']",
            children: (<div></div>),
          },
          contactProps: {
            contactCardProps: {
              layout: EContactCardLayout.horizontal
            }
          },
          carouselProps: {},
          galleryProps: {
            showCaptions: false
          }
        },
        rentalLayoutProps: {
          vitalsProps: {
            className: "mb-3 inverted",
          },
          detailsProps: {
            title: "Full Details",
            className: "max-w-screen-xl mx-auto",
            layout: EListingDetailsLayout.groupColumns,
          },
          similarCarouselProps: {
            title: "Similar Rentals",
          },
          summaryProps: {
            className: "max-w-screen-xl mx-auto p-4",
            showFeatures: false,
          },
          rowClassName: "max-w-screen-xl mx-auto",
          mapProps: { title: "", className: "my-10" },
          formProps: {
            classNames: "inverted strictTwoColumn property-contact",
            title: "Request Information",
            inputTextAreaLabel: "Add a message here...",
          },
          showContactUsForm: true,
          contactUsFormProps: {
            checkboxGroups: {
              label: "I am interested in the following",
              groups: [
                { list: ["Having a broker contact me", "Vacation rentals"] },
                {
                  list: [
                    "Edgartown",
                    "Oak Bluffs",
                    "West Tisbury",
                    "Chilmark",
                    "Vineyard Haven",
                    "Aquinnah",
                  ],
                },
              ],
            },
          },
        },
        listingSearchProps: {
          primaryFilters: listingStandardFilters,
          secondaryFilters: listingMiddleFilters,
          sort: listingSort,
          filterClassName: "inverted w-full",
          headerClassName: "max-w-screen-xl mx-auto w-full",
          pagerClassName: "max-w-screen-xl mx-auto",
          mapClassName: "w-full",
          resultsClassName: "max-w-screen-2xl mx-auto gap-6 px-6 md:[&>.listing-card]:w-[calc((100%_-_24px)/2)] xl:[&>.listing-card]:w-[calc((100%_-_48px)/3)]",
          className: "flex flex-col items-center w-full"
        },
        listingEntrySearchProps: {
          standardFilters: listingEntryFilters,
          buttonClassName: "border border-white hover:bg-button-bg-inverted",
        },
        communitySearchProps: {
          primaryFilters: communityStandardFilters,
          secondaryFilters: listingMiddleFilters,
          filterClassName: "inverted",
          headerClassName: "max-w-screen-xl mx-auto",
          pagerClassName: "max-w-screen-xl mx-auto",
          resultsClassName: "max-w-screen-2xl mx-auto",
          mapClassName: "w-screen",
          sort: listingSort,
        },
        rentalSearchProps: {
          sort: rentalSort,
          primaryFilters: rentalStandardFilters,
          filterClassName: "inverted text-white",
          headerClassName: "max-w-screen-xl mx-auto",
          pagerClassName: "max-w-screen-xl mx-auto",
          mapClassName: "w-screen",
          resultsClassName: "max-w-screen-2xl mx-auto",
        },
        peopleSearchProps: {
          headerClassName: "max-w-screen-lg mx-auto",
          pagerClassName: "max-w-screen-lg mx-auto",
          resultsClassName: "max-w-screen-2xl mx-auto",
          className: "flex flex-col items-center",
          showCount: false,
        },
        globalPeopleSearchCardProps: {
          imageWsrvTransform: EWsrvTransform.thumb1x1
        },
        rentalEntrySearchProps: {
          standardFilters: rentalEntryFilters,
          buttonClassName: "border border-white hover:bg-button-bg-inverted",
        },
        rentalEntryAdvancedSearchProps: {
          standardFilters: rentalEntryAdvancedFilters,
          buttonClassName: "border border-white hover:bg-button-bg-inverted",
          onSubmit: async (filters: any) => {
            let featuresFilter: string[] = []
            filters["air"] && featuresFilter.push("A/C")
            filters["waterfront"] && featuresFilter.push("Waterfront")
            filters["linens"] && featuresFilter.push("Linens Provided")
            filters["pool"] && featuresFilter.push("Pool")

            if (featuresFilter.length > 0) {
              filters = {
                ...filters,
                features: featuresFilter,
              }
            }

            const params = new URLSearchParams({
              filters: JSON.stringify(filters),
            })
            await navigate(
              data.config.rentalSearchPath +
              "#" +
              params.toString()
            )
          },
        },
        globalContactFormProps: {
          layout: EContactFormLayout.column2static,
          invertedForm: false,
          inputTextClassNames: "[&::placeholder]:!text-[var(--color-body-text)] font-['EB_Garamond_Regular'] text-sm",
          inputTextAreaLabel: "Message...",
          inputTextAreaClassName: "font-['EB_Garamond_Regular']",
          children: (<div></div>),
        },
        globalSellFormProps: {
          layout: ESellFormLayout.row2map,
          propertyTypes: [
            {
              type: "Residential",
              subtypes: [
                "Single Family Residence",
                "Stock Cooperative",
                "Condominium",
                "Apartment",
                "Townhouse",
                "Mobile Home",
                "Mixed Use",
                "Office",
              ],
            },
            {
              type: "Rental",
              subtypes: [
                "Apartment",
                "Single Family Residence",
                "Condominium",
                "Multi Family",
                "Stock Cooperative",
                "Duplex",
                "Townhouse",
                "Mixed Use",
                "Residential",
                "Mobile Home",
                "Triplex",
                "House (detached)",
                "Office",
                "Condotel",
              ],
            },
            {
              type: "Detached Home",
              subtypes: [],
            },
            {
              type: "Multi-family",
              subtypes: [
                "Multi Family",
                "Duplex",
                "Triplex",
                "Townhouse",
              ],
            },
            {
              type: "Commercial",
              subtypes: [
                "Mixed Use",
                "Office",
                "Retail",
                "Industrial",
                "Warehouse",
                "Apartment",
                "Townhouse",
              ],
            },
            {
              type: "Lots & Land",
              subtypes: ["Unimproved Land"]
            },
            {
              type: "Condo",
              subtypes: [],
            },
            {
              type: "Business Opportunity",
              subtypes: [
                "Retail",
                "Mixed Use",
                "Industrial",
                "Office",
                "Apartment",
                "Warehouse",
              ]
            },
            {
              type: "Co-op",
              subtypes: [],
            },
            {
              type: "Multi-family Townhouse",
              subtypes: [],
            },
            {
              type: "Single-family Townhouse",
              subtypes: [],
            },
            {
              type: "Land",
              subtypes: [],
            },
            {
              type: "House (detached)",
              subtypes: [],
            },
            {
              type: "Vacant Land",
              subtypes: [],
            },
            {
              type: "Condop",
              subtypes: [],
            },
            {
              type: "Commercial Building",
              subtypes: [],
            },
            {
              type: "House (attached)",
              subtypes: [],
            },
            {
              type: "House W/accessory",
              subtypes: [],
            },
          ],
          propertyTypeSelectLabel: "Property Type",
          propertySubtypeSelectLabel: "Sub-Type",
          bedsSelect: {
            placeholder: "Beds",
            selectOptions: [
              {
                label: "1",
                value: "1",
              },
              {
                label: "2",
                value: "2",
              },
              {
                label: "3",
                value: "3",
              },
              {
                label: "4",
                value: "4",
              },
              {
                label: "5",
                value: "5",
              },
              {
                label: "6",
                value: "6",
              },
              {
                label: "7+",
                value: "7+",
              },
            ]
          },
          bathsSelect: {
            placeholder: "Baths",
            selectOptions: [
              {
                label: "1",
                value: "1",
              },
              {
                label: "2",
                value: "2",
              },
              {
                label: "3",
                value: "3",
              },
              {
                label: "4",
                value: "4",
              },
              {
                label: "5",
                value: "5",
              },
              {
                label: "6",
                value: "6",
              },
              {
                label: "7+",
                value: "7+",
              },
            ]
          },
          squareFootageSelect: {
            placeholder: "Sq. Ft.",
            selectOptions: [
              {
                label: "Under 1000 ft²",
                value: "Under 1000 ft²",
              },
              {
                label: "1000-2000 ft²",
                value: "1000-2000 ft²",
              },
              {
                label: "2000-3000 ft²",
                value: "2000-3000 ft²",
              },
              {
                label: "3000-4000 ft²",
                value: "3000-4000 ft²",
              },
              {
                label: "4000-5000 ft²",
                value: "4000-5000 ft²",
              },
              {
                label: "5000-6000 ft²",
                value: "5000-6000 ft²",
              },
              {
                label: "6000-7000 ft²",
                value: "6000-7000 ft²",
              },
              {
                label: "7000-8000 ft²",
                value: "7000-8000 ft²",
              },
              {
                label: "Over 8000 ft²",
                value: "Over 8000 ft²",
              },
            ]
          },
          conditionSelect: {
            placeholder: "Condition",
            selectOptions: [
              {
                label: "Excelent",
                value: "Excelent",
              },
              {
                label: "Good",
                value: "Good",
              },
              {
                label: "Average",
                value: "Average",
              },
              {
                label: "Fair",
                value: "Fair",
              },
              {
                label: "Poor",
                value: "Poor",
              },
            ],
          },
          inputTextAreaLabel: "Additional information...",
        },
        globalListingCardProps: {
          vitalsTwoLineAddress: true,
          vitalsLocationIcon: false,
          vitalsLayout: EListingVitalsLayout.column1Border1,
          className: "border border-body-bg-inverted",
          vitalsProps: {
            priceClassName: "!text-base tracking-wider leading-5",
            locationClassName: "!text-xs !font-light !uppercase !tracking-wider leading-6",
            vitalIconsClassName: "!text-xs !font-light !uppercase !tracking-wider leading-6",
          },
        },
        globalCommunitiesContentWithTabsProps: {
          heroCarouselProps: {
            slickSettings: {
              prevArrow: <LeftArrow />,
              nextArrow: <RightArrow />,
              className: "flex flex-row items-center"
            }
          },
          tabsProps: {
            tabListItemClassNames: {
              active: "active"
            }
          },
          communityBladeProps: {
            className: "communities-section",
            columnsWrapperClass: "max-w-screen-2xl border-b border-t border-accent"
          },
          developmentsBladeProps: {
            className: "developments-section",
            columnsWrapperClass: "max-w-screen-2xl"
          },
          listingCarouselProps: {
            className: "slick-center-zoom pt-20 pb-28",
            slickSettings: {
              prevArrow: <LeftArrow />,
              nextArrow: <RightArrow />,
              dots: true,
              appendDots: (dots: any) => (
                <div>
                  <ul className="flex flex-row justify-center items-baseline border-b gap-4">
                    {dots}
                  </ul>
                </div>
              ),
              customPaging: (i: number) => (
                <div className="pb-4">
                  {`${i + 1 < 10 ? "0" : ""}${i + 1}`}
                </div>
              ),
              centerPadding: "22%",
              responsive: [
                {
                  breakpoint: 767,
                  settings: {
                    centerPadding: "0px",
                    dots: true,
                    appendDots: (dots: any) => (<ul>{dots}</ul>),
                    customPaging: (i: number) => (
                      <button>
                        {i + 1}
                      </button>
                    ),
                  },
                },
              ]
            }
          },
          listingCarouselCardsProps: {
            imageProps: {
              lazy: false
            },
            vitalsTwoLineAddress: false,
            linkText: "View Property"
          },
          developmentsCarouselProps: {
            prevSlideButtonContent: (
              <svg width="92" height="47" viewBox="0 0 92 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="path-1-inside-1_416_3714" fill="white">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M91.9922 46.4042L45.9952 0.407227L-0.00183051 46.4042L91.9922 46.4042Z" />
                </mask>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M91.9922 46.4042L45.9952 0.407227L-0.00183051 46.4042L91.9922 46.4042Z" fill="#FFFFF8" fill-opacity="0.7" />
                <path d="M45.9952 0.407227L46.7023 -0.29988L45.9952 -1.00699L45.2881 -0.29988L45.9952 0.407227ZM91.9922 46.4042L91.9922 47.4042L94.4064 47.4042L92.6993 45.6971L91.9922 46.4042ZM-0.00183051 46.4042L-0.708938 45.6971L-2.41605 47.4042L-0.00183049 47.4042L-0.00183051 46.4042ZM45.2881 1.11433L91.2851 47.1113L92.6993 45.6971L46.7023 -0.29988L45.2881 1.11433ZM0.705277 47.1113L46.7023 1.11433L45.2881 -0.29988L-0.708938 45.6971L0.705277 47.1113ZM-0.00183049 47.4042L91.9922 47.4042L91.9922 45.4042L-0.00183052 45.4042L-0.00183049 47.4042Z" fill="#C19B5F" mask="url(#path-1-inside-1_416_3714)" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M46.7461 22.2797L46.7461 45.4072L45.2461 45.4072L45.2461 22.2797L38.0536 29.4647L36.9961 28.4072L45.2461 20.1572L45.2461 14.2797L38.0536 21.4647L36.9961 20.4072L45.9961 11.4072L54.9961 20.4072L53.9386 21.4722L46.7461 14.2797L46.7461 20.1572L54.9961 28.4072L53.9386 29.4722L46.7461 22.2797Z" fill="#002349" />
              </svg>
            ),
            nextSlideButtonContent: (
              <svg width="92" height="47" viewBox="0 0 92 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="path-1-inside-1_416_3713" fill="white">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M5.48509e-07 0.410217L45.997 46.4072L91.994 0.410218L5.48509e-07 0.410217Z" />
                </mask>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.48509e-07 0.410217L45.997 46.4072L91.994 0.410218L5.48509e-07 0.410217Z" fill="#FFFFF8" fill-opacity="0.7" />
                <path d="M45.997 46.4072L45.2899 47.1143L45.997 47.8214L46.7041 47.1143L45.997 46.4072ZM5.48509e-07 0.410217L5.60434e-07 -0.589783L-2.41421 -0.589783L-0.707106 1.11732L5.48509e-07 0.410217ZM91.994 0.410218L92.7011 1.11733L94.4082 -0.589782L91.994 -0.589782L91.994 0.410218ZM46.7041 45.7001L0.707107 -0.29689L-0.707106 1.11732L45.2899 47.1143L46.7041 45.7001ZM91.2869 -0.296889L45.2899 45.7001L46.7041 47.1143L92.7011 1.11733L91.2869 -0.296889ZM91.994 -0.589782L5.60434e-07 -0.589783L5.36584e-07 1.41022L91.994 1.41022L91.994 -0.589782Z" fill="#C19B5F" mask="url(#path-1-inside-1_416_3713)" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M45.2461 24.5347L45.2461 1.40723L46.7461 1.40723L46.7461 24.5347L53.9386 17.3497L54.9961 18.4072L46.7461 26.6572L46.7461 32.5347L53.9386 25.3497L54.9961 26.4072L45.9961 35.4072L36.9961 26.4072L38.0536 25.3422L45.2461 32.5347L45.2461 26.6572L36.9961 18.4072L38.0536 17.3422L45.2461 24.5347Z" fill="#002349" />
              </svg>
            ),

            activeSlideIndicator: (
              <svg width="133" height="133" viewBox="0 0 133 133" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="66.2031" y="131.7" width="92.6263" height="92.6263" transform="rotate(-135 66.2031 131.7)" fill="#FFFFF8" fill-opacity="0.7" stroke="#C19B5F" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M71.4062 66.9531H43.4062V65.4531H71.4062L105.534 65.4531L92.3487 52.2606L93.4062 51.2031L107.656 65.4531H113.534L100.349 52.2606L101.406 51.2031L116.406 66.2031L101.406 81.2031L100.341 80.1456L113.534 66.9531H107.656L93.4062 81.2031L92.3412 80.1456L105.534 66.9531L71.4062 66.9531Z" fill="#002349" />
              </svg>
            )
          },
          baseStandardFilters: communitiesTabsGlobalFilters,
          baseEntrySearchProps: {
            onSubmit: async (filters: any) => {
              const params = new URLSearchParams({
                filters: JSON.stringify(filters),
              })
              await navigate(data.config.listingsSearchPath + "#" + params.toString() + "&sort=\"priceDESC\"&showMap=true")
            },
            buttonClassName: "bg-accent"
          }
        },
        footerLayout: FooterLayoutFormColumn,
        footerProps: {
          logoClassName: "!w-[176px] !mb-4",
          secondaryLogoClassName: "!w-[125px]",
          copyright: `© ${new Date().getFullYear()} All rights reserved.`,
          formProps: {
            classNames: "strictTwoColumn allFields",
            fieldsetClassName: "!p-0",
            mainLegendClassName: "hidden",
            mainListClassName: "!my-0",
            checkboxGroups: {
              groups: [
                { list: ["I am interested in buying a property", "I am interested in selling a property"] }
              ]
            },
            checkboxGroupsWrapperClassNames: "sm:!justify-start",
            checkboxListClassName: "!grid-rows-none",
            inputTextClassNames: "!text-composite-text [&::placeholder]:!text-composite-text !border-composite-text font-['EB_Garamond_Regular'] text-sm",
            submitButtonClassName: "!border-none !w-auto uppercase",
            clickHandler: async (formInput: any) => {
              const recaptchaToken = await getRecaptchaToken("footer");
              await axios({
                url: process.env.LEAD_CREATE_API_ENDPOINT,
                method: "POST",
                data: {
                  recaptchaToken,
                  formValues: formInput,
                  propertyInfo: {
                    createdFrom: `Footer - SKK Real Estate`,
                  },
                },
              })
            }
          }
        },
        globalListingCarouselProps: {
          slickSettings: {
            arrows: false,
            slidesToShow: 1,
            variableWidth: false,
            centerPadding: "22%",
            responsive: [
              {
                breakpoint: 767,
                settings: {
                  centerPadding: "0px",
                  dots: true,
                  appendDots: (dots: any) => (<ul>{dots}</ul>),
                  customPaging: (i: number) => (
                    <button>
                      {i + 1}
                    </button>
                  ),
                },
              },
            ]
          }
        },
        locationSearchProps: {
          titleClassName: "text-left text-[32px] tracking-[5px] text-composite-text uppercase pt-32",
        },
        listingSearchCompactProps: {
          primaryFilters: compactSearchFilters,
          sort: listingSort,
          filterClassName: "text-white py-2.5 bg-body-bg-inverted px-2 lg:px-4",
          formClassName: "!max-w-screen-2xl",
          pagerClassName: "max-w-screen-xl mx-auto relative font-light",
          mapClassName: "w-full",
          resultsClassName: "mx-auto",
          className: "compact-search-module mb-16 [&_>_div_>_button.absolute]:!px-5 [&_>_div_>_button.absolute]:!py-2.5 [&_>_div_>_div_>_div:last-child]:text-center",
          defaultSort: "onMktTSDESC",
        },
        communityLayoutProps: {
          listingsResultPageSize: 6,
          requestInfoFormProps: {
            inputTextClassNames: "border-h1-text font-['EB_Garamond_Regular'] text-sm w-full",
            inputTextAreaClassName: "!h-32 border-h1-text font-['EB_Garamond_Regular'] w-full",
            hideReCaptchaText: true,
          },
          marketStatsProps: {
            title: "Market Stats",
          }
        },
        communityPageLayout: CommunityLayoutHeroBox,
        agentLayoutProps: {
          className: "[&_.tabbed-content-blade_.search-results]:gap-6 md:[&_.tabbed-content-blade_.listing-card]:w-[calc((100%_-_24px)/2)] xl:[&_.tabbed-content-blade_.listing-card]:w-[calc((100%_-_48px)/3)]",
          contactCardProps: {
            agentInfoClassName: "[&>div:nth-child(3)]:font-['EB_Garamond_Regular'] [&>div:nth-child(3)]:text-[15px]"
          }
        },
        siteMenuItems: siteMenuItems,
      }}
    >
      {children}
    </SiteContext.Provider>
  )
}
